import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './../constants/firebase';
import Header from '../header/header';
import './food.css';

const FoodView = () => {
  const [food, setFood] = useState(null);
  const params = useParams();
  const food_id = params.id;

  useEffect(() => {
      const unsubscribe = db.ref(`platos/${food_id}`).on('value', (snapshot) => {
          const data = snapshot?.val() ?? {};
          setFood(data);
      });
      
      return () => {
          unsubscribe();
      };
  }, [food_id]);

  if (!food) return null;  // Este condicional es para asegurarnos de que el plato ha sido cargado.

  return (
    <div className="container">
      <Header />
      <div className="food-content">
        <div className="food-image" style={{ backgroundImage: `url(${food.imageUrl})` }} />
        <div className="food-details">
          <h1 className="food-title">{food.name}</h1>
          <p className="price">$ {food.price}</p>
          {food.description && <p className="description">{food.description}</p>}
        </div>
      </div>
      <footer>
        <p>TODOS NUESTROS PRODUCTOS INCLUYEN IMPUESTOS</p>
      </footer>
    </div>
  );
};

export default FoodView;