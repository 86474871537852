import * as Images from '../Images';

const food_list = [
  { name: 'ENTRADAS FRÍAS', image: Images.carpaccio, rute: 'entrada-fria' },
  { name: 'ENTRADAS CALIENTES', image: Images.entradas, rute: 'entradas-calientes', },
  { name: 'CARNES A LA PARRILLA', image: Images.carne, rute: 'carnes-parrilla', },
  { name: 'ESPECIALES DEL MAR', image: Images.pescado, rute: 'especiales-mar', },
  { name: 'LA ESPECIALIDAD CON POLLO', image: Images.chuleta, rute: 'especiales-pollo', },
  { name: 'ESPECIALES CON CERDO', image: Images.cerdo, rute: 'especiales-cerdo', },
  { name: 'ENSALADAS', image: Images.ensalada, rute: 'ensaladas', },
  { name: 'COMIDAS RÁPIDAS', image: Images.hamburguesa, rute: 'comidas-rapidas', },
  { name: 'DESAYUNOS TURÍN', image: Images.desayuno, rute: 'desayunos', },
  // { name: 'Cocteles', image: Images.coctel, rute: 'cocteles', },
  // { name: 'Cafés', image: Images.granizado, rute: 'cafes', },
  // { name: 'Postres', image: Images.postres, rute: 'postres', },
];

const foods = {
  'entrada-fria':
  {
    title: 'Entradas Frías', values: [
      { id: 'ceviche-camaron', name: 'CEVICHE DE CAMARÓN', price: '26.000', description: 'camarón tigre, cebolla, salsa de tomate, limón y jugo de naranja', imageUrl: Images.cevichecamaron },
      { id: 'ceviche-corvina-peruano', name: 'CEVICHE DE CORVINA AL ESTILO PERUANO', price: '30.000', description: 'corvina, limón, pimentón y cebolla morada', imageUrl: Images.cevicheperuano },
      { id: 'carpaccio-salmon', name: 'CARPACCIO DE SALMÓN', price: '35.000', description: 'salmón en láminas, vinagre balsámico, limón en jugo y acompañado con un timbal de verduras', },
      { id: 'carpaccio-res', name: 'CARPACCIO DE LOMO DE RES', price: '27.000', description: 'lomo viche de res en láminas, vinagre balsámico, limón en jugo y acompañado con un timbal de verduras', imageUrl: Images.carpacciores },
      { id: 'coctel-langostinos', name: 'COCTEL DE LANGOSTINOS', price: '40.000', description: 'langostinos, jugo de naranja y limón, cebolla, salsa de tomate' },
    ]
  },
  'entradas-calientes': {
    title: 'ENTRADAS CALIENTES', values: [
      { id: 'crema-tomate', name: 'CREMA DE TOMATE AL GRATIN', price: '17.000', description: 'tomates, cebolla, ajo, vino blanco, queso parmesano y se gratina con tomates secos', imageUrl: Images.crematomate },
      { id: 'ceditos-mozarella', name: 'DEDITOS DE QUESO MOZZARELLA', price: '20.000', description: 'bastones de queso apanados en huevo y harina de pan + coco crocante', imageUrl: Images.dedos },
      { id: 'crema-maiz-pollo-coco', name: 'CREMA DE MAÍZ POLLO Y COCO', price: '19.000', description: 'salsa bechamel, base de pollo, pollo picado, crema de coco blanco, maíz, sal pimienta, vino blanco', imageUrl: Images.cremamaiz },
      { id: 'polpete-napolitana', name: 'POLPETTE A LA NAPOLITANA', price: '30.000', description: 'carne molida de res, queso parmesano, salsa napolitana, vino tinto y pan', imageUrl: Images.polpete },
    ]
  },
  'carnes-parrilla': {
    title: 'CARNES A LA PARRILLA', values: [
      { id: 'baby-romana', name: 'BABY BEEF', price: '42.000', description: 'lomo viche de res, papa, sour, timbal de verduras, plátano maduro en almíbar', imageUrl: Images.baby },
      { id: 'churrasco-argentino', name: 'CHURRASCO', price: '39.000', description: 'lomo de res ancho, timbal de verduras, papa, sour, plátano maduro en almíbar' },
      { id: 'steak-pimienta', name: 'STEAK PIMIENTA', price: '39.000', description: 'lomo viche de res, salsa demi-glace, papa francesa' },
      { id: 'lomo-vino-tinto', name: 'LOMO VICHE FIBRILADO', price: '44.000', description: 'lomito viche de res en salsa de champiñones vino tinto seco' },
      { id: 'punta-anca', name: 'PUNTA DE ANCA', price: '39.000', description: 'punta de lomo de res, papa, sour, timbal de verduras y plátano maduro en almíbar', imageUrl: Images.carne },
      { id: 'mixto-carnes', name: 'MIXTO DE TRES CARNES', price: '40.000', description: 'pollo, res y cerdo, madurito acaramelado y ensalada timbal' },
      { id: 'spaguetti-solomillo', name: 'SPAGHETTI CON SOLOMILLO', price: '37.000', description: 'spaghetti, lomito viche de res, salsa napolitana y demi-glace', imageUrl: Images.spagueti },
      { id: 'bronuice-peruana', name: 'LOMITO DE RES A LA PERUANA', price: '0', description: 'puntas de lomo, arroz blanco, papa a la francesa' },
      { id: 'filet-mignon', name: 'FILET MIGNON', price: '45.000', description: 'lomo viche de res, salsa demi-glace con champiñón, tocineta y papa francesa', imageUrl: Images.filet },
    ]
  },
  'especiales-mar': {
    title: 'ESPECIALES DEL MAR', values: [
      { id: 'cazuela-camarones', name: 'CAZUELA DE MARISCOS EN SALSA DE CHONTADURO', price: '48.000', description: 'mixtura de mariscos, camarones, bisque de mariscos, chontaduro' },
      { id: 'armonia-epoca', name: 'ARMONÍA DE PEZ BELLA EPOCA', price: '53.000', description: 'salmón, mango picado y almendras', imageUrl: Images.armonia },
      { id: 'trucha-ajillo', name: 'TRUCHA A LA PLANCHA O AL AJILLO', price: '50.000', description: 'trucha entera, ajo, limón, perejil, mantequilla, plátano verde' },
      { id: 'tilapia-frita', name: 'TILAPIA FRITA', price: '36.000', description: 'tilapia entera, limón,  plátano verde' },
      { id: 'pargo-coco', name: 'PARGO ENTERO EN SALSA DE COCO', price: '54.000', description: 'pargo, crema de leche, crema de coco, ajo' },
      { id: 'langostinos-piedra', name: 'LANGOSTINOS PIEDRA', price: '59.000', description: 'langostinos, ajo, salsa de soya, miel y papa francesa' },
      { id: 'langostinos-tierra', name: 'LANGOSTINOS MAR Y TIERRA', price: '50.000', description: 'langostinos, ajo, mantequilla, dos miñonetas de lomo de res, salsa demi-glace' },
    ]
  },
  'especiales-pollo': {
    title: 'LA ESPECIALIDAD CON POLLO', values: [
      { id: 'churrasco-pollo', name: 'POLLO PARRILLA', price: '36.000', description: 'pechuga de pollo, papa, sour, plátano en almíbar, timbal de verduras' },
      { id: 'pollo-champinones', name: 'POLLO EN SALSA DE CHAMPIÑÓN AL GRATIN', price: '40.000', description: 'pechuga de pollo, salsa bechamel, champiñón, queso parmesano', imageUrl: Images.pollochampi },
      { id: 'pollo-hawaiana', name: 'POLLO EN SALSA HAWAIANA', price: '39.000', description: 'pechuga de pollo, piña, uvas pasas, salsa demi-glace, manzanas, pimentón, vino tinto, azúcar', imageUrl: Images.pollo },
      { id: 'chuleta-pollo', name: 'MILANESA DE POLLO', price: '34.000', description: 'pollo en pechuga, panko, huevo, papa francesa', imageUrl: Images.chuleta },
    ]
  },
  'especiales-cerdo': {
    title: 'ESPECIALES CON CERDO', values: [
      { id: 'chuleta', name: 'Chuleta', price: '38.000', description: 'lomo de cerdo, panko, huevo y papa francesa', imageUrl: Images.chuleta },
      { id: 'lomo-bombay', name: 'LOMO DE CERDO SALSA TROPICAL', price: '46.000', description: 'lomo de cerdo, jugo y mango en fruta, manzana roja y verde, uvas, jugo naranja' },
      { id: 'lomo-hawaiano', name: 'LOMO DE CERDO HAWAIANO', price: '46.000', description: 'lomo de cerdo, piña, uvas pasas, salsa demi-glace, manzanas, pimentón, vino tinto, azúcar', imageUrl: Images.lomohawaiano },
      { id: 'costillas-san', name: 'COSTILLAS DE CERDO SAN LUIS', price: '49.000', description: 'salsa de tomate, coca cola, azúcar, vinagre, hojas de laurel, hierbas' },
      { id: 'costillas-mostaza', name: 'LOMO DE CERDO EN SALSA DE LULO', price: '35.000', description: 'jugo de lulo, mantequilla, sal y crema de leche' },
    ]
  },
  'ensaladas': {
    title: 'ENSALADAS', values: [
      { id: 'ensalada-thailandes', name: 'ENSALADA DE POLLO TAILANDESA', price: '29.000', description: 'pollo, lechuga, zanahoria, apio, calabacin, vermichelly, ajonjolí, pimentón, raíces chinas.' },
      { id: 'ensalada-primavera', name: 'ENSALADA PRIMAVERA', price: '30.000', description: 'mix de lechuga, huevos de codorniz, champiñón, apio, maíz, jamón, vinagre y pollo', imageUrl: Images.primavera },
      { id: 'ensalada-sofia', name: 'ENSALADA SOFÍA', price: '28.000', description: 'mixtura de lechugas, fresa, mango, apio, ajonjolí y vinagre', imageUrl: Images.sofia },
      { id: 'ensalada-vegetariana', name: 'ENSALADA VEGETARIANA', price: '24.000', description: 'zanahoria, calabacín de ambos, pimentón, cebolla apio,champiñón, ajonjolí, aceite de oliva, raíces chinas y ajonjolí negro', imageUrl: Images.ensalada },
    ]
  },
  'comidas-rapidas': {
    title: 'COMIDAS RÁPIDAS', values: [
      { id: 'club-sandwich', name: 'CLUB SÁNDWICH', price: '40.900', description: 'jamón, loncha de queso, carne de lomo, pollo, tocineta, huevo, pan, papa francesa, lechuga, tomate', imageUrl: Images.sandwich },
      { id: 'hamburguesa-turin', name: 'HAMBURGUESA ESPECIAL TURÍN', price: '27.200', description: 'carne 200 g, tomate, lechuga, queso tipo cheddar, pan artesanal, tocineta, papa francesa', imageUrl: Images.hamburguesa1 },
      { id: 'sandwich-gratin', name: 'SÁNDWICH DE POLLO GRATINADO', price: '30.000', description: 'pan tajado, pollo, salsa bechamel, papa francesa, tomate, lechuga y queso parmesano' },
      { id: 'hamburguesa-vegetariana', name: 'HAMBURGUESA VEGETARIANA', price: '20.000', description: 'lentejas  200 g, pan artesanal, tomate, lechuga, cebolla blanca y salsa Turín y papa francesa' },
      { id: 'hamburguesa-callejera', name: 'HAMBURGUESA AL ESTILO CALLEJERA', price: '29.800', imageUrl: Images.hamburguesa, description: 'carne 200 g en dos partes, pan artesanal, tocineta, tomate, lechuga, queso, cebolla blanca, salsa Turín y papa francesa' },
      { id: 'salchipapa-turin', name: 'SALCHIPAPA', price: '20.000', description: 'papa francesa, salchicha llanera' },
      { id: 'salchiqueso', name: 'SALCHIQUESO', price: '30.000', description: 'salchicha, papa francesa y queso' },
      { id: 'salchichorizo', name: 'SALCHICHORIZO', price: '32.000', description: 'papa francesa y chorizo' },
      { id: 'salchipicada', name: 'SALCHIPICADA', price: '32.000', description: 'costilla de cerdo, chorizo, papa francesa, papa amarilla, salsa Turín' },
      { id: 'alitas -bbq', name: 'ALITAS EN SALSA MIEL MOSTAZA o B.B.Q.', price: '22.000', description: 'alitas x 6', imageUrl: Images.alitas },
    ]
  },
  'desayunos': {
    title: 'DESAYUNOS TURÍN', values: [
      { id: 'desayuno-continental', name: 'DESAYUNO CONTINENTAL', price: '0', description: 'fruta, jugo de naranja, café, té o chocolate y un croissant' },
      { id: 'desayuno-celestina', name: 'DESAYUNO CON TORTILLA CELESTINA', price: '0', description: 'fruta, jugo de naranja, café, té o chocolate, un croissant, huevos y espinacas', imageUrl: Images.americano },
      { id: 'tortilla-champinones', name: 'DESAYUNO CON TORTILLA + CHAMPIÑONES', price: '0', description: 'fruta, jugo de naranja, café, té o chocolate, un croissant, huevos, champiñones' },
      { id: 'tortilla-espanola', name: 'DESAYUNO CON TORTILLA ESPAÑOLA', price: '0', description: 'fruta, jugo de naranja, café, té o chocolate, un croissant, papa amarilla, mantequilla, salchicha llanera, cebolla y huevo' },
      { id: 'brocheta-pollo', name: 'BROCHETAS DE POLLO O SHISTABUK', price: '0', description: 'fruta, jugo de naranja, café, té o chocolate, un croissant, pollo' },
      { id: 'desayuno-turin', name: 'DESAYUNO TURÍN', price: '0', imageUrl: Images.turin, description: 'fruta, jugo de naranja, café, té o chocolate, un croissant, tomates secos, queso mozzarella, lechuga, jamón serrano, aceite de oliva y limón', },
      { id: 'desayuno-tostada-francesas', name: 'DESAYUNO CON TOSTADAS FRANCESAS', price: '0', imageUrl: Images.tostadas, description: 'pan brioche, huevo, leche, harina integral, mermelada de mora, esencia de vainilla', },
      { id: 'parfait', name: 'PARFAIT - COPA DE FRUTAS FRESCAS', price: '0', imageUrl: Images.parfait, description: 'fresas, yogurt y granola', },
      { id: 'croissant', name: 'CROISSANT', price: '0', imageUrl: Images.parfait, description: 'pan croissant, lechuga, tomate, queso y jamón', },
      { id: 'desayuno-montanero', name: 'DESAYUNO MONTAÑERO', price: '0', imageUrl: Images.montanero, description: 'calentado, chorizo, chicharrón, huevo, arepa, tajada de plátano', },
      { id: 'huevo-gusto', name: 'DESAYUNO AMERICANO', price: '0', description: 'fruta, jugo de naranja, café, té o chocolate, un croissant, huevos al gusto' },
      { id: 'omelette-queso', name: 'DESAYUNO CON OMELETTE JAMÓN Y QUESO', price: '0', description: 'jugo, frutas, bebida caliente como café, té o chocolate' },
      { id: 'omelette-pollo', name: 'DESAYUNO CON OMELETTE JAMON, QUESO, MAÍZ', price: '0', imageUrl: Images.champinones, description: 'jugo, frutas, bebida caliente como café, té o chocolate' },
      { id: 'omelette-jamon-queso-maiz', name: 'DESAYUNO CON OMELETTE POLLO, QUESO, MAÍZ', price: '0', description: 'jugo, frutas, bebida caliente como café, té o chocolate' },
      { id: 'omelette-pollo-queso-champinones', name: 'OMELETTE POLLO, CHAMPIÑONES, QUESO', price: '0', description: 'jugo, frutas, bebida caliente como café, té o chocolate' },
      { id: 'changua', name: 'CHANGUA', price: '1.000', description: 'caldo con papa, huevo, leche y cilantro' },
      { id: 'caldo-costilla', name: 'CALDO DE COSTILLA', price: '1.500', description: 'caldo con papa, costilla de res y cilantro' },
      // { id: 'omelette-ranchero-salchica', name: 'Omelette ranchero, salchica, queso y tomate', price: '0', imageUrl: Images.ranchero },
      // { id: 'omelette-ricota-espinaca-queso-crema', name: 'Omelette ricota, espinaca y queso crema', price: '0', imageUrl: Images.espinaca },
      // { name: 'acompañados de fruta, jugo de naranja, bebida caliente (café, té o chocolate)' },
      // { id: 'agua-panela-queso', name: 'Agua de panela con queso', price: '1.500' },
      // { id: 'chocolate-queso', name: 'Chocolate con queso', price: '3.500', imageUrl: Images.chocolate },
    ]
  },

  'cocteles': {
    title: 'Cocteles', values: [
      { id: 'alexander', name: 'Alexander', price: '23.000' },
      { id: 'copa-sangria', name: 'Copa de sangria', price: '23.000', imageUrl: Images.sangria },
      { id: 'caipirina-maracuya', name: 'Caipiriña de maracuya', price: '23.000' },
      { id: 'caipirina-tradicional', name: 'Caipiriña tradicional', price: '23.000' },
      { id: 'tequila-sunrise', name: 'Tequila Sunrise', price: '23.000' },
      { id: 'orgasmo', name: 'Orgasmo', price: '23.000' },
      { id: 'gin-tonic', name: 'Gin & Tonic', price: '23.000' },
      { id: 'daiquiri-limon', name: 'Daiquiri de limón', price: '23.000' },
      { id: 'cuba-libre', name: 'Cuba libre', price: '23.000' },
      { id: 'margarita-blue', name: 'Margarita Blue', price: '23.000' },
      { id: 'margarita-tradicional', name: 'Margarita tradicional', price: '23.000' },
      { id: 'mojito', name: 'Mojito', price: '23.000' },
      { id: 'pina-colada', name: 'Piña colada', price: '23.000' },
      { id: 'sex-beach', name: 'Sex on the beach', price: '23.000' },
      { id: 'montecristo', name: 'Montecristo', price: '23.000' },
      { id: 'opisco-sour', name: 'Opisco Sour', price: '23.000' },
      { id: 'long-tea', name: 'Long Island Ice tea', price: '27.000' },
      { id: 'fruit-punch', name: 'Fruit Punch', price: '20.000' },
      { id: 'martini', name: 'Martini', price: '0' },
    ]
  },
  'cafes': {
    title: 'Café Turin', values: [
      { id: 'cafe-americano', name: 'Café Americano', price: '4.000', imageUrl: Images.cafeamericano },
      { id: 'capuchino-baileys', name: 'Capuchino con crema baileys', price: '12.000', imageUrl: Images.capuchinobaileys },
      { id: 'expreso', name: 'Expreso', price: '4.000' },
      { id: 'capuchino-licor', name: 'Capuchino sin licor', price: '8.000', imageUrl: Images.capuchino },
      { id: 'macchiato', name: 'Macchiato', price: '7.000' },
      { id: 'cafe-late', name: 'Café Late', price: '7.000' },
      { id: 'irish-coffee', name: 'Irish Coffee', price: '8.000' },
      { id: 'ice-coffee', name: 'Ice coffee', price: '8.000', imageUrl: Images.icecoffee },
      { id: 'chocolate', name: 'Chocolate', price: '5.000' },
      { id: 'café-moka', name: 'Café Moka', price: '7.000', imageUrl: Images.cafemoka },
      { id: 'milo', name: 'Milo', price: '4.000', imageUrl: Images.milo },
      { id: 'granizado-cafe', name: 'Granizado de café', price: '15.000', imageUrl: Images.granizado },
      { id: 'granizado-oreo', name: 'Granizado de oreo', price: '15.000' },
      { id: 'granizado-chocolate', name: 'Granizado de chocolate', price: '15.000', imageUrl: Images.granizadochoc },
      { id: 'milo-frío', name: 'Milo frío', price: '7.000' },
      { id: 'te-frutos-rojos', name: 'Té frutos rojos', price: '0', imageUrl: Images.terojos },
      { id: 'te-frutos-verdes', name: 'Té frutos verdes', price: '0' },
    ]
  },
  'postres': {
    title: 'Postres', values: [
      { id: 'Oreo', name: 'Oreo', price: '0', imageUrl: Images.oreo },
      { id: 'maracuya', name: 'Maracuya', price: '0', imageUrl: Images.maracuya },
      { id: 'copa-cream', name: 'Copa cream', price: '0', imageUrl: Images.copa },
    ]
  },
  'fritos-del-carrito': {
    title: 'Fritos del carrito', values: [
      { id: 'churros', name: 'Churros', price: '1.000' },
      { id: 'pandebono', name: 'Pandebono', price: '1.500' },
      { id: 'bunuelo', name: 'Buñuelo', price: '1.500' },
      { id: 'papa-rellena', name: 'Papa rellena', price: '3.500' },
      { id: 'repingacho-carne', name: 'Repingacho con carne', price: '5.000' },
      { id: 'repingacho-carne-arroz', name: 'Repingacho con carne y arroz', price: '3.500' },
      { id: 'croissant', name: 'Croissant', price: '1.500' },
    ]
  },
  'adicionales-de-desayunos': {
    title: 'ACOMPAÑAMIENTOS O ADICIONALES', values: [
      { id: 'papa-francesa', name: 'Papas a la francesa', price: '0' },
      { id: 'porcion-arroz-blanco', name: 'Porción de arroz blanco', price: '0' },
      { id: 'porcion-coco', name: 'Porción de arroz con coco', price: '0' },
      { id: 'porcion-ensalada', name: 'Porción de ensalada de la casa', price: '0' },
      { id: 'porcion-cuajada', name: 'Porción de queso quajada (100 g)', price: '0' },
      { id: 'porcion-loncha', name: 'Porción queso loncha (2 tajadas)', price: '0' },
      { id: 'porcion-carne', name: 'Porción carne a la plancha (res, pollo o cerdo 125g)', price: '0' },
      { id: 'porcion-sopa', name: 'Porción sopa del día (12 onzas)', price: '0' },
      { id: 'porcion-chantilly', name: 'Porción chantilly', price: '0' },
    ]
  },
};

export { food_list, foods };