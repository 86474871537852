import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAE5ob65XONW28zukj3GNjtuvYPpKiqJn8",
    authDomain: "agencia-sm.firebaseapp.com",
    databaseURL: "https://agencia-sm-default-rtdb.firebaseio.com",
    projectId: "agencia-sm",
    storageBucket: "agencia-sm.appspot.com",
    messagingSenderId: "462637360437",
    appId: "1:462637360437:web:ef6f99ee406e17ad44187c",
    measurementId: "G-GKF9MGXQQC"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.database();
const storage = firebase.storage();
const auth = firebase.auth();


export { db, storage, auth };