import React, { useEffect, useState } from 'react';
import { db } from './../constants/firebase';
import * as Images from '../Images';
import './recommended.css';
import './dot_pagination.css';
import './slider.css';

const Recommended = () => {
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  useEffect(() => {
    const unsubscribe = db.ref('platos').on('value', (snapshot) => {
      const data = snapshot?.val() ?? {};
      const loadedPlatos = [];
      for (let id in data) {
        if (data[id].isRecommended) {
          loadedPlatos.push({
            id,
            name: data[id].name,
            description: data[id].description,
            price: parseInt(data[id].price).toLocaleString('es-CO', {
              minimumFractionDigits: 0,
              style: 'currency',
              currency: 'COP'
            }),
            imageUrl: data[id].imageUrl,
          });
        }
      }
      setRecommendedProducts(loadedPlatos);
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      {recommendedProducts && recommendedProducts.length > 0 && <div className='recommended-container'>
        <h2 className='recommended-title'>Recomendados</h2>
        <div className='slider-pagination-wrapper'>
          <Slider items={recommendedProducts} />
        </div>
      </div>}
    </>
  );
};

export default Recommended;


const Slider = ({ items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const itemsPerPage = 2;
  const totalSlides = Math.ceil(items.length / itemsPerPage);

  const goToSlide = (slideIndex) => {
    if (slideIndex < 0 || slideIndex >= totalSlides) return;
    setCurrentSlide(slideIndex);
  };

  return (
    <div className="slider">
      <div
        className="slider-wrapper"
        style={{
          transform: `translateX(-${(currentSlide * 2 * 50) + currentSlide * 5.5}%)`, // Ajustamos el valor del desplazamiento
          transition: 'transform 0.5s ease',
        }}
      >
        {items.map((item) => (
          <div className={'item-wrapper'}>
            <div key={item.id} className="item">
              <div className='item-image' style={{ backgroundImage: `url(${item.imageUrl})` }} />
              <div className='item-details'>
                <h3>{item.name}</h3>
                <p>{item.price}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="dots">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <div
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            key={index}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};
