import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Componentes del usuario
import Header from './header/header';
import Recommended from './recommended/recommended';
import Menu from './menu/menu';
import Category from './screens/category';
import Food from './screens/food';

// Componentes del administrador
import AdminHeader from './header/header-admin';
import AdminLogin from './login';
import AdminDashboard from './admin';
import AdminCategories from './admin/categories';
import AdminDishes from './admin/dishes';

import useAuth from './constants/useAuth';

function PrivateRouteWrapper({ children }) {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;  // O puedes devolver tu componente de carga aquí.
  }

  return isAuthenticated ? children : <Navigate to="/" replace />;
}



const RoutesComponent = () => {
  const siteType = process.env.REACT_APP_SITE_TYPE;
  if (siteType === 'ADMIN') {
    return (
      <Routes>
        <Route path="/" element={
          <>
            <AdminHeader />
            <AdminLogin />
          </>
        } />
        <Route path="/home" element={
          <PrivateRouteWrapper>
            <AdminHeader />
            <AdminDashboard />
          </PrivateRouteWrapper>
        } />
        <Route path="/categories" element={
          <PrivateRouteWrapper>
            <AdminHeader />
            <AdminCategories />
          </PrivateRouteWrapper>
        } />
        <Route path="/dishes" element={
          <PrivateRouteWrapper>
            <AdminHeader />
            <AdminDishes />
          </PrivateRouteWrapper>
        } />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={
          <>
            <Header />
            <Recommended />
            <Menu />
          </>
        } />
        <Route path="/category/:name" element={<Category />} />
        <Route path="/:category_name/food/:id" element={<Food />} />
      </Routes>
    );
  }

}

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <RoutesComponent />
      </div>
    </Router>
  );
}

export default App;