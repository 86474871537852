import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from './../constants/firebase';
import './menu.css';

const Menu = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const unsubscribe = db.ref('categories').on('value', (snapshot) => {
      const data = snapshot?.val() ?? {};
      const loadedCategories = [];
      for (let id in data) {
        if (data[id].active) {
          loadedCategories.push({
            id,
            name: data[id].title,
            rute: data[id].id,
            image: data[id].imageUrl
          });
        }
      }
      setCategories(loadedCategories);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="row">
      {categories.map((category) => (
        <div className="menu__item" key={category.rute}>
          <Link to={`/category/${category.rute}`}>
            <div className="img" style={{ backgroundImage: `url(${category.image})` }} />
            <div className="menu__item--button">
              <p>{category.name}</p>
            </div>
          </Link>
        </div>
      ))}
    </div >
  );
};

export default Menu;