import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { db } from './../constants/firebase';
import Header from '../header/header';
import './category.css';

const CategoryView = () => {
    const [category, setCategory] = useState(null);
    const [foods, setFoods] = useState([]);
    const params = useParams();
    const categoryId = params.name;

    useEffect(() => {
        const unsubscribe = db.ref(`categories/${categoryId}`).on('value', (snapshot) => {
            const data = snapshot?.val() ?? {};
            setCategory(data);
        });

        const dishesUnsubscribe = db.ref('platos').orderByChild('category').equalTo(categoryId).on('value', (snapshot) => {
            const data = snapshot?.val() ?? {};
            const fetchedFoods = [];
            for (let id in data) {
                if (data[id].isAvailable) {
                    fetchedFoods.push({
                        ...data[id],
                        id: id
                    });
                }
            }
            console.log(fetchedFoods);
            setFoods(fetchedFoods);
        });

        return () => {
            unsubscribe();
            dishesUnsubscribe();
        };
    }, [categoryId]);

    return (
        <div className="container">
            <Header />
            {category && <h1 className="category-title">{category.title}</h1>}
            <div className="grid">
                {foods.map((food, index) => (
                    <Link to={`/${categoryId}/food/${food.id}`} key={food.id}>
                        <div className="food-item">
                            <div className="food-img" style={{ backgroundImage: `url(${food.imageUrl})` }} />
                            <div className="food-info">
                                <h2 className="food-name">{food.name}</h2>
                                {food.description && <p className="food-description">{food.description}</p>}
                                <p className="food-priceo">${food.price}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default CategoryView;