import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from './../constants/firebase';
import logo from '../Images/turin-logo.png';
import './header.css';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const unsubscribe = db.ref('categories').on('value', (snapshot) => {
      const data = snapshot?.val() ?? {};
      const fetchedCategories = [];
      for (let id in data) {
        if (data[id].active) {
          fetchedCategories.push({
            ...data[id],
          });
        }
      }
      setCategories(fetchedCategories);
    });
    return () => unsubscribe();
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="header-container">
      <div className="logo-container">
        <div className={`nav-toggle ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
          <i className={`fas ${isNavOpen ? 'fa-times' : 'fa-bars'}`} />
        </div>
        <Link to={'/'}>
          <img src={logo} alt="Logo del restaurante" />
        </Link>
      </div>
      <nav className={`nav-container${isNavOpen ? ' open' : ''}`}>
        <ul className={`nav-list${isNavOpen ? ' open' : ''}`}>
          {categories.map(category => (
            <li key={category.id}>
              <Link to={`/category/${category.id}`} onClick={toggleNav}>
                {category.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
